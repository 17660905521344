/* Navbar.css */

.navbar {
    /* background-color: #fff; */
    padding: 1rem;
}

.navbar-brand {
    color: aliceblue !important;
    font-size: 1.5rem;
    font-weight: bold;
}

.navbar-nav .nav-link {
    margin-right: 1rem;
    font-size: 1.5rem;
    color: aliceblue !important;
}

.navbar-nav .nav-link:hover {
    color: #1890ff;
}

.d-flex .ant-btn-primary {
    background-color: #1890ff;
    border-color: #1890ff;
}

.d-flex .ant-btn-link {
    color: #1890ff;
}

.navbar-brand {
    font-size: 2rem;
    /* Increase the size of the brand font */
    font-weight: bold;
    color: #333;
    /* Change the brand color */
}
.navbar-toggler {
    background-color: white; /* Change background to white */
    border: 1px solid white; /* Change border to white */
}

.navbar-toggler-icon {
    background-image: none; /* Remove default icon background */
}

.navbar-toggler:focus {
    outline: none; /* Remove focus outline */
    box-shadow: none; /* Remove box shadow */
}

/* Optionally, you can add a custom icon */


.navbar-toggler-icon:before {
    content: "";
    display: block;
    width: 30px; /* Width of the icon */
    height: 3px; /* Height of the icon */
    background-color: white; /* Color of the icon */
    position: relative;
}
