.crdfet{
    background: transparent !important;
    border: none !important;
}
.divimg{
    width: 100px !important;
    height: 100px !important;
    margin: auto;
    background: gray;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 45% 15px 15px 45% !important ;
}
.aLink{
    text-decoration: none !important;
    color: aliceblue;
}
.crdfetimg{
    width: 70% !important;
    height: 70% !important;
    object-fit: cover;
}
.imgMaain{
    margin-top: 150px !important;
}