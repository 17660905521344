.per{
    font-size: xx-large;
    color: brown;
}
.subtitle{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.ulwlc{
    margin-top: 50px;
    margin-left: 25px;
}
/* Container Styles */
.bonus {
    padding: 50px 0;
    position: relative;
    overflow: hidden;
}

/* Title */
.title {
    text-align: center;
    margin-bottom: 30px;
    color: #fff;
}

/* Glassy card effect */
.glass-card {
    background: rgba(255, 255, 255, 0.2);
    border-radius: 15px;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    color: #fff;
    transition: transform 0.3s ease-in-out;
}

.glass-card:hover {
    transform: scale(1.05);
}

/* Card body text */
.card-body {
    text-align: center;
}

/* Bonus list styling */
.ulwlc {
    list-style-type: none;
    padding: 0;
    color: #fff;
}

.ulwlc li {
    margin: 10px 0;
    font-size: 18px;
}

.per {
    color: #ff5733;
    font-weight: bold;
}

.animated-bg {
    background-size: 400% 400%;
    border-radius: 15px;
    padding: 30px;
}

.card-img-top{
    height: 400px;
    width: 450px;
    border-radius: 15px;
}
.bonrow{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 100px;
}

@media (max-width: 768px) {
    .card-img-top{
        height: 300px;
        width: 330px;
    }
    .c2{
        margin-top: 60px;
    }
    .ulwlc{
        margin: 0;
        margin-top: 50px;
    }
    .per{
        font-size: large;
    }
}