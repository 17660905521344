.USP{
    background: url('../../../photo/background.jpg');
    background-size: cover;
    background-position: center;
}
.contUsp{
    padding: 150px 50px !important;
}
.contUsp h1{
    margin: 20px 0px;
    color: brown;
}.USP {
    position: relative; /* Ensure the pseudo-element is positioned relative to the parent */
    background: url('../../../photo/background.jpg');
    background-size: cover;
    background-position: center;
}

.USP::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.568); /* Black layer with 50% opacity */
    z-index: 1; /* Ensure it is behind the content but on top of the background */
}

.contUsp {
    position: relative; /* Make sure the content stays above the overlay */
    padding: 150px 50px;
    z-index: 2; /* Ensure content appears above the overlay */
}

.contUsp h1 {
    margin: 20px 0;
    color: brown;
}
