.footer {
    background-color: #333;
    color: #fff;
    padding: 20px 0;
    text-align: center;
  }
  
  .container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
  }
  
  .footer-links {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: center;
  }
  
  .footer-links li {
    margin: 0 20px;
  }
  
  .footer-links a {
    color: #fff;
    text-decoration: none;
  }
  
  .footer-links a:hover {
    color: #ccc;
  }
  
  /* Responsive styles */
  
  @media (max-width: 768px) {
    .footer-links {
      flex-direction: column;
    }
    .footer-links li {
      margin: 10px 0;
    }
  }