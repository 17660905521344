.benefit-title {
    font-weight: bold;
    font-size: 1.2rem;
    position: relative;
}

.benefit-number {
    font-size: 1.8rem;
    color: #007bff;
    font-weight: bold;
    display: block;
    margin-bottom: 5px;
}

.cardimg {
    width: 50%;
    height: 130px !important;
    display: block;
    margin-bottom: 25px;
    margin: auto;
    margin-top: 30px;
}
.cardben{
    background: transparent !important;
    border: none !important;
}