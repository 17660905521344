@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@200..700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@200..700&family=Source+Sans+3:ital,wght@0,200..900;1,200..900&display=swap');

body {
  background: #131313;
  margin: 0;
  padding: 0;
  color: #f5f5dc;
  font-family: 'Source Sans 3';
}
h1, h2, h3, h4, h5, h6 {
  font-family: 'Oswald';
}

.App {
  font-family: 'Lato', sans-serif;
  position: relative;
  overflow: hidden; 
}

.starry-line-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  overflow: hidden;
  background: radial-gradient(ellipse at bottom, #1b2735 0%, #090a0f 100%);
}

/* Starry background effect */
.starry-line-bg::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: transparent;
  z-index: -1;
  box-shadow: 
    0px 0px 2px 1px white,
    10px 20px 2px 2px white,
    50px 50px 3px 1px white,
    100px 150px 4px 2px white,
    150px 200px 1px 3px white,
    200px 250px 2px 2px white,
    250px 300px 3px 2px white,
    300px 350px 2px 1px white,
    350px 400px 4px 2px white,
    400px 450px 2px 2px white,
    450px 500px 1px 3px white,
    500px 550px 3px 2px white;
}

.title h1,
.title-1 h1 {
  position: relative;
  font-size: 3.5rem;
  font-weight: 600;
}

.title h1::before {
  content: "";
  position: absolute;
  left: 50%;
  bottom: 5px;
  width: 250px;
  height: 16px;
  transform: skew(-12deg) translateX(-50%);
  background: #414141;
  z-index: -1;
}

.title-1 h1::before {
  content: "";
  position: absolute;
  left: 50%;
  bottom: 5px;
  width: 550px;
  height: 16px;
  transform: skew(-12deg) translateX(-50%);
  background: #414141;
  z-index: -1;
}

.title,
.title-1 {
  color: #FFF225;
  text-align: center;
  margin-top: 120px;
  margin-bottom: 120px;
}

/* App.css */

.floating-button {
  position: fixed;
  bottom: 430px;   /* Distance from the bottom */
  right: 16px;    /* Distance from the right */
  background-color: #202020; /* Background color */
  color: #FFF225; /* Text color */
  border: none;   /* No border */
  border-radius: 25px; /* Rounded corners */
  padding: 10px 20px; /* Padding */
  font-size: 16px; /* Font size */
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.2); /* Shadow */
  cursor: pointer; /* Pointer cursor on hover */
  z-index: 1000; /* Ensures it stays above other content */
  transition: background-color 0.3s, color 0.3s; /* Smooth transition */
}

/* Hover effect */
.floating-button:hover {
  background-color: #F9ED32; /* Change background on hover */
  color: #202020; /* Change text color on hover */
}


.IG {
  position: fixed;
  bottom: 350px;   /* Distance from the bottom */
  right: 16px;    /* Distance from the right */
  background-color: #202020; /* Background color */
  border: none;   /* No border */
  border-radius: 25px; /* Rounded corners */
  padding: 10px 20px; /* Padding */
  font-size: 16px; /* Font size */
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.2); /* Shadow */
  cursor: pointer; /* Pointer cursor on hover */
  z-index: 1000; /* Ensures it stays above other content */
  transition: background-color 0.3s, color 0.3s; /* Smooth transition */
  color: #fff225;
}

/* Hover effect */
.IG:hover {
  background-color: #F9ED32; /* Change background on hover */
  color: #202020; /* Change text color on hover */
}

.what{
  padding: 25px !important;
  display: flex;    
  align-items: center;
  justify-content: center;
}
.demo{
  color: #fff225;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 160px;
}
