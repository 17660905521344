.title {
    color: #f5f5dc;
}

.imgau {
    height: 450px;

}

.imgdiv {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.imgdiv1 {
    display: flex;
    justify-content: center;
    align-items: start;
    flex-direction: column;
}

.button {
    display: flex;
    justify-content: center;
    align-items: center;
}

.awesome-button {
    font-size: 1.2rem;
    margin-top: 20px;
}

.animated-button {
    font-size: 18px;
    font-weight: bold;
    text-decoration: none;
    padding: 15px 30px;
    color: #fff;
    background-color: #ff6f61;
    border: 2px solid #ff6f61;
    border-radius: 30px;
    transition: all 0.4s ease;
    position: relative;
    overflow: hidden;
}

/* Hover effect - background animation */
.animated-button:hover {
    background-color: #fff;
    color: #ff6f61;
}

/* Adding before pseudo-element for extra animation */
.animated-button::before {
    content: "";
    position: absolute;
    top: 0;
    left: -100%;
    width: 300%;
    height: 500%;
    background: rgba(255, 111, 97, 0.2);
    transition: all 0.6s ease;
}

.animated-button:hover::before {
    left: 0;
    transform: rotate(45deg);
}

@media (max-width: 768px) {
    .imgau {
        height: 350px;
        margin-top: 60px;
    }
}