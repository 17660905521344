.home{
    padding: 100px 10vw !important;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
}
.home h1 {
    font-size: 2.5rem;
    margin-bottom: 20px;
}
.h1typ{
    color: #ff5722;
}

.typewriter-text span {
    border-right: 2px solid #ff5722; /* Cursor */
}
